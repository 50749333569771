<template>
    <div class="TalentRecruitmentIndex">
        <div class="head f-c-b">
            <div class="LeftPart f-c">
                <img class="logo" src="@/assets/Community/logo.png" alt="">
                <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main f-c-b">
            <div class="item f-cl-b" @click="$router.push('/TalentRecruitmentList')">
                <span>岗位招聘</span>
                <img src="@/assets/Community/job.png" alt="">
            </div>
            <div class="item f-cl-b" @click="$router.push('/TalentMarketList')">
                <span>人才市场</span>
                <img src="@/assets/Community/market.png" alt="">
            </div>
            <div class="item f-cl-b" @click="$router.push('/PersonalCenter')">
                <span>个人中心</span>
                <img src="@/assets/Community/personal.png" alt="">
            </div>
            <div class="item f-cl-b" @click="$router.push('/BusinessManagementIndex')">
                <span>企业管理</span>
                <img src="@/assets/Community/manage.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TalentRecruitmentIndex',
    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    },
}

</script>
<style lang='less' scoped>
.TalentRecruitmentIndex {
    .head {
        padding: 20px 0;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            width: 55px;
            // cursor: pointer;

            .logo {
                width: 53px;
                height: 53px;
                margin-right: 18px;
            }

            .shiliaologo {
                width: 77px;
                height: 38px;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .main {
        margin-top: 60px;
        width: 1280px;
        height: 200px;

        // border: 1px solid red;
        .item {
            width: 255px;
            height: 135px;
            background: #F8F8F8;
            border-radius: 30px;
            padding: 40px 25px 25px 25px;
            cursor: pointer;
            span {
                font-size: 30px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
            }

            img {
                width: 62px;
                height: 62px;
            }
        }
    }
}</style>
